// La sesión ha expirado y necesita una nueva clave par.
export const UUID_EXPIRED = 'C401000101';

// Error genérico de credenciales inválidas. No cuenta como intento.
export const USER_INVALID_CRED = 'C4010000';

// Usuario y/o contraseña incorrectos. Cuenta como intento.
export const USER_NOT_FOUND = 'C401000201';

// Usuario sin aplicaciones en assisted-channels
export const USER_NOT_APPLICATIONS = 'C4040000';

// El usuario será temporalmente bloqueado.
export const USER_WILL_BE_TEMP_BLOCKED = 'C401000202';

// El usuario ha sido temporalmente bloqueado.
export const USER_WAS_TEMP_BLOCKED = 'C401000203';

// El usuario será bloqueado.
export const USER_WILL_BE_PERMANENTLY_BLOCKED = 'C401000204';

// El usuario ha sido bloqueado.
export const USER_WAS_PERMANENTLY_BLOCKED = 'C401000205';

// El token de usuario recordado no es válido o lo era pero ha caducado.
export const REMEMBER_TOKEN_INVALID = 'C401000207';

// La petición no es válida porque requiere subida de nivel se la sesión.
export const SCA_REQUIRED = 'C403000003';

// Se requiere una clave única para poder continuar.
// La clave única se ha enviado al teléfono del cliente.
export const OTP_REQUIRED = 'C403000001';

// La clave única enviada no es válida.
export const OTP_INVALID = 'C403000101';

// Se ha recibido OTP_INVALID 3 veces y tras el aviso, se ha vuelto intentar la OTP fallando nuevamente.
export const OTP_LAST_CHANCE = 'C403000105';

// El usuario ha pedido una nueva clave única
export const OTP_RENEWED = 'C403000103';

// La clave única enviada ha expirado (expira en dos minutos).
export const OTP_EXPIRED = 'C403000102';

// Error al validar la clave única. Este código suele salir al tercer intento.
// Probablemente el usuario ha sido bloqueado.
export const OTP_ERROR = 'C403000002';

//El usuario no disponible de un teléfono móvil para la realización del restablecimiento de contraseña.
export const OTP_NO_TELEPHONE_ERROR = 'C400000205';

//El usuario no se encuentra activo para la realización del restablecimiento de contraseña.
export const OTP_NO_ACTIVE_USER_ERROR = 'C400000206';

// Para realizar dicha operación es necesario que el usuario introduzca el código enviado por email.
export const OTP_EMAIL_CODE = 'C403000106';

// El servidor no ha respondido en el tiempo establecido.
export const REQUEST_TIMEOUT = 'ECONNABORTED';

// Beneficiario (de envio) no encontrado. El teléfono no está registrado en bizum.
export const BIZUM_DESTINATION_NOT_FOUND = 'C400000004';

// Beneficiario (de solicitud) no encontrado. El teléfono no está registrado en bizum.
export const BIZUM_ISSUER_NOT_FOUND = 'C400000005';

// Beneficiario no encontrado y con una solicitud de invitación pendiente.
export const BIZUM_DESTINATION_IS_PENDING = 'C400000012';

// Importe superior al límite máximo.
export const BIZUM_MAX_LIMIT = 'C400000006';

// Importe inferior al límite mínimo.
export const BIZUM_MIN_LIMIT = 'C400000007';

// Se ha superado el importe máximo por día.
export const BIZUM_DAILY_AMOUNT_LIMIT = 'C400000008';

// Se ha superado el número de operaciones máximas por día.
export const BIZUM_DAILY_LIMIT = 'C400000009';

// Se ha superado el número de operaciones máximas de envíos al mes.
export const BIZUM_SEND_MONTHLY_LIMIT = 'C400000010';

// Se ha superado el número de operaciones máximas de solicitudes al mes.
export const BIZUM_REQUEST_MONTHLY_LIMIT = 'C400000013';

// Saldo disponible insuficiente.
export const BIZUM_NO_AVAILABLE_BALANCE = 'C400000019';

// Se ha superado el tiempo límite para confirmar la operación.
export const BIZUM_TIMEOUT = 'C408000001';

// Se ha superado el saldo disponible en la cuenta para realizar la operación.
export const BIZUM_BALANCE_LIMIT = 'C400000011';

// Se ha superado el número de intentos máximos para la portabilidad
export const BIZUM_OTP_ERROR = 'C403000104';

// El teléfono destino es el mismo que el teléfono emisor.
export const BIZUM_DESTINATION_PHONE_NOT_VALID = 'C412000003';

// El teléfono del usuario ya se encuentra registrado en bizum.
export const BIZUM_PHONE_ALREADY_EXISTS = 'C412000001';

// El email del usuario ya se encuentra registrado en bizum.
export const BIZUM_EMAIL_ALREADY_EXISTS = 'C412000002';

// No se ha podido incluir el texto y/o imagen a la operación.
export const BIZUM_ERROR_ADDITIONAL_CONTENT_TYPE1 = 'C200000000';
export const BIZUM_ERROR_ADDITIONAL_CONTENT_TYPE2 = 'C200000001';
export const BIZUM_ERROR_ADDITIONAL_CONTENT_TYPE3 = 'C200000002';
export const BIZUM_ERROR_ADDITIONAL_CONTENT_TYPE4 = 'C200000003';
export const BIZUM_ERROR_ADDITIONAL_CONTENT_TYPE5 = 'C200000004';

// La operativa on está disponible
export const BIZUM_ERROR_OPERATIVE_NOT_AVAILABLE = 'C500000011';

// El usuario no está bloqueado por número de intentos fallidos
export const USER_NOT_PERMANENTLY_BLOCKED = 'C400000204';

// The webview session has expired
export const WEBVIEW_UUID_EXPIRED = 'C406000000';

// Constante de Provincias de Assisted Channels
export const PROVINCES = {
	'02': 'Albacete',
	'03': 'Alicante/Alacant',
	'04': 'Almería',
	'01': 'Araba/Álava',
	'33': 'Asturias',
	'05': 'Ávila',
	'06': 'Badajoz',
	'07': 'Balears, Illes',
	'08': 'Barcelona',
	'48': 'Bizkaia',
	'09': 'Burgos',
	'10': 'Cáceres',
	'11': 'Cádiz',
	'39': 'Cantabria',
	'12': 'Castellón/Castelló',
	'13': 'Ciudad Real',
	'14': 'Córdoba',
	'15': 'Coruña, A',
	'16': 'Cuenca',
	'20': 'Gipuzkoa',
	'17': 'Girona',
	'18': 'Granada',
	'19': 'Guadalajara',
	'21': 'Huelva',
	'22': 'Huesca',
	'23': 'Jaén',
	'24': 'León',
	'25': 'Lleida',
	'27': 'Lugo',
	'28': 'Madrid',
	'29': 'Málaga',
	'30': 'Murcia',
	'31': 'Navarra',
	'32': 'Ourense',
	'34': 'Palencia',
	'35': 'Palmas, Las',
	'36': 'Pontevedra',
	'26': 'Rioja, La',
	'37': 'Salamanca',
	'38': 'Santa Cruz de Tenerife',
	'40': 'Segovia',
	'41': 'Sevilla',
	'42': 'Soria',
	'43': 'Tarragona',
	'44': 'Teruel',
	'45': 'Toledo',
	'46': 'Valencia/València',
	'47': 'Valladolid',
	'49': 'Zamora',
	'50': 'Zaragoza',
	'51': 'Ceuta',
	'52': 'Melilla',
};

// Constante de Paises de Assisted Channels
export const COUNTRIES = {
	AFG: 'Afganistán',
	ALA: 'Åland, Islas',
	ALB: 'Albania',
	DEU: 'Alemania',
	AND: 'Andorra',
	AGO: 'Angola',
	AIA: 'Anguila',
	ATA: 'Antártida',
	ATG: 'Antigua y Barbuda',
	SAU: 'Arabia Saudita',
	DZA: 'Argelia',
	ARG: 'Argentina',
	ARM: 'Armenia',
	ABW: 'Aruba',
	AUS: 'Australia',
	AUT: 'Austria',
	AZE: 'Azerbaiyán',
	BHS: 'Bahamas (las)',
	BGD: 'Bangladesh',
	BRB: 'Barbados',
	BHR: 'Bahrein',
	BEL: 'Bélgica',
	BLZ: 'Belice',
	BEN: 'Benin',
	BMU: 'Bermudas',
	BLR: 'Belarús',
	BOL: 'Bolivia (Estado Plurinacional de)',
	BES: 'Bonaire, San Eustaquio y Saba',
	BIH: 'Bosnia y Herzegovina',
	BWA: 'Botswana',
	BRA: 'Brasil',
	BRN: 'Brunei Darussalam',
	BGR: 'Bulgaria',
	BFA: 'Burkina Faso',
	BDI: 'Burundi',
	BTN: 'Bhután',
	CPV: 'Cabo Verde',
	KHM: 'Camboya',
	CMR: 'Camerún',
	CAN: 'Canadá',
	QAT: 'Qatar',
	TCD: 'Chad',
	CHL: 'Chile',
	CHN: 'China',
	CYP: 'Chipre',
	COL: 'Colombia',
	COM: 'Comoras (las)',
	PRK: 'Corea (la República Popular Democrática de)',
	KOR: 'Corea (la República de)',
	CIV: "Côte d'Ivoire",
	CRI: 'Costa Rica',
	HRV: 'Croacia',
	CUB: 'Cuba',
	CUW: 'Curaçao',
	DNK: 'Dinamarca',
	DMA: 'Dominica',
	ECU: 'Ecuador',
	EGY: 'Egipto',
	SLV: 'El Salvador',
	ARE: 'Emiratos Árabes Unidos (los)',
	ERI: 'Eritrea',
	SVK: 'Eslovaquia',
	SVN: 'Eslovenia',
	ESP: 'España',
	USA: 'Estados Unidos de América (los)',
	EST: 'Estonia',
	ETH: 'Etiopía',
	PHL: 'Filipinas (las)',
	FIN: 'Finlandia',
	FJI: 'Fiji',
	FRA: 'Francia',
	GAB: 'Gabón',
	GMB: 'Gambia (la)',
	GEO: 'Georgia',
	GHA: 'Ghana',
	GIB: 'Gibraltar',
	GRD: 'Granada',
	GRC: 'Grecia',
	GRL: 'Groenlandia',
	GLP: 'Guadeloupe',
	GUM: 'Guam',
	GTM: 'Guatemala',
	GUF: 'Guayana Francesa',
	GGY: 'Guernsey',
	GIN: 'Guinea',
	GNB: 'Guinea Bissau',
	GNQ: 'Guinea Ecuatorial',
	GUY: 'Guyana',
	HTI: 'Haití',
	HND: 'Honduras',
	HKG: 'Hong Kong',
	HUN: 'Hungría',
	IND: 'India',
	IDN: 'Indonesia',
	IRQ: 'Iraq',
	IRN: 'Irán (República Islámica de)',
	IRL: 'Irlanda',
	BVT: 'Bouvet, Isla',
	IMN: 'Isla de Man',
	CXR: 'Navidad, Isla de',
	ISL: 'Islandia',
	CYM: 'Caimán, (las) Islas',
	CCK: 'Cocos / Keeling, (las) Islas',
	COK: 'Cook, (las) Islas',
	FRO: 'Feroe, (las) Islas',
	SGS: 'Georgia del Sur (la) y las Islas Sandwich del Sur',
	HMD: 'Heard (Isla) e Islas McDonald',
	FLK: 'Malvinas [Falkland], (las) Islas',
	MNP: 'Marianas del Norte, (las) Islas',
	MHL: 'Marshall, (las) Islas',
	PCN: 'Pitcairn',
	SLB: 'Salomón, Islas',
	TCA: 'Turcas y Caicos, (las) Islas',
	UMI: 'Islas Ultramarinas Menores de los Estados Unidos (la)',
	VGB: 'Vírgenes británicas, Islas',
	VIR: 'Vírgenes de los Estados Unidos, Islas',
	ISR: 'Israel',
	ITA: 'Italia',
	JAM: 'Jamaica',
	JPN: 'Japón',
	JEY: 'Jersey',
	JOR: 'Jordania',
	KAZ: 'Kazajstán',
	KEN: 'Kenya',
	KGZ: 'Kirguistán',
	KIR: 'Kiribati',
	KWT: 'Kuwait',
	LAO: 'Lao, (la) República Democrática Popular',
	LSO: 'Lesotho',
	LVA: 'Letonia',
	LBN: 'Líbano',
	LBR: 'Liberia',
	LBY: 'Libia',
	LIE: 'Liechtenstein',
	LTU: 'Lituania',
	LUX: 'Luxemburgo',
	MAC: 'Macao',
	MKD: 'Macedonia (la ex República Yugoslava de)',
	MDG: 'Madagascar',
	MYS: 'Malasia',
	MWI: 'Malawi',
	MDV: 'Maldivas',
	MLI: 'Malí',
	MLT: 'Malta',
	MAR: 'Marruecos',
	MTQ: 'Martinique',
	MUS: 'Mauricio',
	MRT: 'Mauritania',
	MYT: 'Mayotte',
	MEX: 'México',
	FSM: 'Micronesia (Estados Federados de)',
	MDA: 'Moldova (la República de)',
	MCO: 'Mónaco',
	MNG: 'Mongolia',
	MNE: 'Montenegro',
	MSR: 'Montserrat',
	MOZ: 'Mozambique',
	MMR: 'Myanmar',
	NAM: 'Namibia',
	NRU: 'Nauru',
	NPL: 'Nepal',
	NIC: 'Nicaragua',
	NER: 'Níger (el)',
	NGA: 'Nigeria',
	NIU: 'Niue',
	NFK: 'Norfolk, Isla',
	NOR: 'Noruega',
	NCL: 'Nueva Caledonia',
	NZL: 'Nueva Zelandia',
	OMN: 'Omán',
	NLD: 'Países Bajos (los)',
	PAK: 'Pakistán',
	PLW: 'Palau',
	PSE: 'Palestina, Estado de',
	PAN: 'Panamá',
	PNG: 'Papua Nueva Guinea',
	PRY: 'Paraguay',
	PER: 'Perú',
	PYF: 'Polinesia Francesa',
	POL: 'Polonia',
	PRT: 'Portugal',
	PRI: 'Puerto Rico',
	GBR: 'Reino Unido de Gran Bretaña e Irlanda del Norte (el)',
	ESH: 'Sahara Occidental',
	CAF: 'República Centroafricana (la)',
	CZE: 'Chequia',
	COG: 'Congo (el)',
	COD: 'Congo (la República Democrática del)',
	DOM: 'Dominicana, (la) República',
	REU: 'Reunión',
	RWA: 'Rwanda',
	ROU: 'Rumania',
	RUS: 'Rusia, (la) Federación de',
	WSM: 'Samoa',
	ASM: 'Samoa Americana',
	BLM: 'Saint Barthélemy',
	KNA: 'Saint Kitts y Nevis',
	SMR: 'San Marino',
	MAF: 'Saint Martin (parte francesa)',
	SPM: 'San Pedro y Miquelón',
	VCT: 'San Vicente y las Granadinas',
	SHN: 'Santa Helena, Ascensión y Tristán de Acuña',
	LCA: 'Santa Lucía',
	STP: 'Santo Tomé y Príncipe',
	SEN: 'Senegal',
	SRB: 'Serbia',
	SYC: 'Seychelles',
	SLE: 'Sierra leona',
	SGP: 'Singapur',
	SXM: 'Sint Maarten (parte neerlandesa)',
	SYR: 'República Árabe Siria',
	SOM: 'Somalia',
	LKA: 'Sri Lanka',
	SWZ: 'Swazilandia',
	ZAF: 'Sudáfrica',
	SDN: 'Sudán (el)',
	SSD: 'Sudán del Sur',
	SWE: 'Suecia',
	CHE: 'Suiza',
	SUR: 'Suriname',
	SJM: 'Svalbard y Jan Mayen',
	THA: 'Tailandia',
	TWN: 'Taiwán (Provincia de China)',
	TZA: 'Tanzania, República Unida de',
	TJK: 'Tayikistán',
	IOT: 'Territorio Británico del Océano Índico (el)',
	ATF: 'Tierras Australes Francesas (las)',
	TLS: 'Timor-Leste',
	TGO: 'Togo',
	TKL: 'Tokelau',
	TON: 'Tonga',
	TTO: 'Trinidad y Tobago',
	TUN: 'Túnez',
	TKM: 'Turkmenistán',
	TUR: 'Turquía',
	TUV: 'Tuvalu',
	UKR: 'Ucrania',
	UGA: 'Uganda',
	URY: 'Uruguay',
	UZB: 'Uzbekistán',
	VUT: 'Vanuatu',
	VAT: 'Santa Sede (la)',
	VEN: 'Venezuela (República Bolivariana de)',
	VNM: 'Viet Nam',
	WLF: 'Wallis y Futuna',
	YEM: 'Yemen',
	DJI: 'Djibouti',
	ZMB: 'Zambia',
	ZWE: 'Zimbabwe',
};

// Tipos de estados en Assisted Channels
export const STATES = {
	0: 'Onboarding OK',
	1: 'Onboarding KO',
	2: 'Contratación pendiente',
	3: 'Contratación en proceso',
	4: 'Contratación OK',
	5: 'Contratación KO',
	6: 'Alta OK',
};

// Tipos de motivos en Assisted Channels
export const REASONS = {
	0: 'Onboarding OK',
	1: 'Caída onboarding',
	2: 'Evidencias KO',
	3: 'AML KO - Listas Negras UE',
	4: 'Pendiente Banca Electrónica',
	5: 'Pendiente tarjeta',
	6: 'Pendiente firma',
	7: 'Pendiente activación',
	8: 'Cliente KO',
	9: 'Producto KO',
	10: 'Banca Electrónica KO',
	11: 'Tarjeta KO',
	12: 'Firma KO',
	13: 'Activación KO',
	14: 'Pendiente activación BE',
	15: 'OTP KO',
	16: 'Documentación pendiente',
	17: 'Pendiente activación tarjeta',
	18: 'Contratación desestimada',
	20: 'Marca OCIC',
	21: 'PRP',
	22: 'Riesgo PBC alto',
	23: 'Pendiente contratación Core',
	24: 'Error Alta en el Core',
	25: 'Pendiente listas negras',
};

export const TYPES = {
	AV: 'Avenida',
	CL: 'Calle',
	CK: 'Callejón',
	CM: 'Camino',
	CR: 'Carretera',
	PS: 'Paseo',
	PJ: 'Pasaje',
	PZ: 'Plaza',
	RB: 'Rambla',
	RD: 'Ronda',
};

export const DAYS = {
	1: '1',
	2: '2',
	3: '3',
	4: '4',
	5: '5',
	6: '6',
	7: '7',
	8: '8',
	9: '9',
	10: '10',
	11: '11',
	12: '12',
	13: '13',
	14: '14',
	15: '15',
	16: '16',
	17: '17',
	18: '18',
	19: '19',
	20: '20',
	21: '21',
	22: '22',
	23: '23',
	24: '24',
	25: '25',
	26: '26',
	27: '27',
	28: '28',
	29: '29',
	30: '30',
	31: '31',
};

export const MONTHS = {
	0: 'Enero',
	1: 'Febrero',
	2: 'Marzo',
	3: 'Abril',
	4: 'Mayo',
	5: 'Junio',
	6: 'Julio',
	7: 'Agosto',
	8: 'Septiembre',
	9: 'Octubre',
	10: 'Noviembre',
	11: 'Diciembre',
};

export const ORIGINS = {
	0: 'Onboarding',
	1: 'Alta a distancia',
};

export const VERTICALS = {
	1: 'Ingeniería',
	2: 'Salud',
};

export const COLLECTIVES = {
	1: 'Ingenieros de Caminos',
	2: 'Farmaceuticos',
};
