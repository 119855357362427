import SessionCache from '@plugins/cache/index';
import {
	OTP_NO_TELEPHONE_ERROR,
	OTP_NO_ACTIVE_USER_ERROR,
} from '@modules/service/constants';

const SET_ACTUAL_VIEW = 'SET_ACTUAL_VIEW';
const SET_IS_DEVICES_LIST_UPDATED = 'SET_IS_DEVICES_LIST_UPDATED';

const cache = new SessionCache('user');

export default {
	namespaced: true,

	state() {
		return {
			actualView: 'home',
			isDevicesListUpdated: null,
		};
	},

	mutations: {
		[SET_ACTUAL_VIEW](state, value) {
			state.actualView = value;
		},

		[SET_IS_DEVICES_LIST_UPDATED](state, value) {
			state.isDevicesListUpdated = value;
		},
	},

	actions: {
		doSwitch({ commit }, value) {
			commit(SET_ACTUAL_VIEW, value);
		},

		changePassword({ dispatch }, password) {
			const url = '/current/user/password';
			const method = 'PUT';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload: { password },
				},
				{ root: true }
			);
		},

		generatePassword({ dispatch, rootState }, { document_id, birth_date }) {
			const { companyId } = rootState.app;
			const url = '/user/recoverypwd';
			const method = 'POST';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload: {
						document_id,
						birth_date,
						channel: 'WEB',
						company_id: companyId,
					},
				},
				{ root: true }
			);
		},

		generateTemporaryPassword({ dispatch }, { email, phone }) {
			const url = '/user/recoverypwd';
			const method = 'PUT';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload: { email, phone },
				},
				{ root: true }
			);
		},

		getPersonalDetails({ dispatch }) {
			const url = '/current/user/';
			const method = 'GET';

			const cacheKey = 'personalDetail';

			if (cache.has(cacheKey)) {
				return cache.get(cacheKey);
			}

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			).then((data) => {
				cache.set(cacheKey, data);

				return cache.get(cacheKey);
			});
		},

		getDevices({ dispatch }) {
			const url = '/current/user/devices';
			const method = 'GET';

			const cacheKey = 'devices';

			if (cache.has(cacheKey)) {
				return cache.get(cacheKey);
			}

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			).then(({ data: { data } }) => {
				cache.set(cacheKey, data);

				return cache.get(cacheKey);
			});
		},

		async getDevice({ dispatch }, deviceId) {
			let items = cache.get('devices');

			/* istanbul ignore next */
			if (!items) {
				items = await dispatch('getDevices');
			}

			return items.find((device) => device.id === deviceId);
		},

		getDeviceSessions({ dispatch }, deviceId) {
			const url = `/current/user/devices/${deviceId}`;
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			);
		},

		deleteDevice({ dispatch }, deviceId) {
			const url = `/current/user/devices/${deviceId}`;
			const method = 'DELETE';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			).then(() => cache.clear());
		},

		deleteDeviceBiometric({ dispatch }, deviceId) {
			const url = `/current/user/devices/${deviceId}/biometric`;
			const method = 'DELETE';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			).then(() => cache.clear());
		},

		getAssistedDevices({ dispatch, rootState }, { data, source, origin }) {
			const userId = data?.userId;
			const userUUID = rootState.secure.uuid;

			const url = '/current/user/devices';
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			).then((res) => {
				source.postMessage(
					{
						name: 'get-user-devices',
						payload: res.data,
						userUUID,
						userId,
					},
					origin
				);
			});
		},

		ssoLogin({ dispatch }) {
			const url = '/current/user/sso-login';
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			);
		},

		getBiometricToken({ dispatch }, { userId, deviceId }) {
			const url = `/current/user/devices/${deviceId}/biometric`;
			const method = 'POST';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload: { userId },
				},
				{ root: true }
			).then(({ data }) => data);
		},

		getMifidConvenience({ dispatch }) {
			const url = '/current/user/mifid/convenience';
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			);
		},

		getMifidClassification({ dispatch }, { userId }) {
			const url = `/users/${userId}/mifid/classification`;
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			);
		},

		setMifidClassification({ dispatch }, { userId }) {
			const url = `/users/${userId}/mifid/classification`;
			const method = 'POST';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			);
		},

		getNotificationMode({ dispatch, rootState }, { data, source, origin }) {
			const userId = data?.userId;
			const userUUID = rootState.secure.uuid;

			const method = 'GET';
			const url = `/notifications/${userId}`;

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			).then(({ data: { smsByEmail, otpDefaultMethod } }) =>
				source.postMessage(
					{
						name: 'update-session',
						payload: {
							isNotificationByEmail: smsByEmail,
							pushInfo: otpDefaultMethod,
						},
						userUUID,
						userId,
					},
					origin
				)
			);
		},

		changeNotificationMode(
			{ dispatch, rootState },
			{ data, source, origin }
		) {
			const { userId, smsByEmail, channel } = data;
			const userUUID = rootState.secure.uuid;

			const method = 'PATCH';
			const url = `/notifications/${userId}`;

			const payload = {
				smsByEmail,
				channel,
			};

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload,
				},
				{ root: true }
			).then(() =>
				source.postMessage(
					{
						name: 'update-session',
						payload: {
							isNotificationByEmail: smsByEmail,
							pushInfo: channel,
						},
						userUUID,
						userId,
					},
					origin
				)
			);
		},

		getNotificationState({ dispatch }, { userId }) {
			const method = 'GET';
			const url = `/notifications/${userId}`;

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			).then(({ data }) => data);
		},

		getPushNotificationState({ dispatch }) {
			const method = 'GET';
			const url = '/notifications/push';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			).then(({ data }) => data);
		},

		setPushNotificationState({ commit, dispatch }, payload) {
			const method = 'POST';
			const url = '/notifications/push';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload,
				},
				{ root: true }
			).then(() => {
				commit(SET_IS_DEVICES_LIST_UPDATED, Date.now());
				cache.clear();
			});
		},

		modifyUserDevice({ dispatch, rootState }, { data, source, origin }) {
			const { userId } = data;
			const userUUID = rootState.secure.uuid;

			const method = 'POST';
			const url = '/current/user/devices';

			const { modifications } = data;

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload: modifications,
				},
				{ root: true }
			).then((res) =>
				source.postMessage(
					{
						name: 'modify-user-device',
						payload: res.data,
						userUUID,
						userId,
					},
					origin
				)
			);
		},

		getUserMessages({ dispatch }, { paginationKey }) {
			const queryParams = {};
			const method = 'GET';
			const url = '/notifications/audit';

			/* istanbul ignore next */
			if (paginationKey) {
				Object.assign(queryParams, {
					paginationKey,
				});
			}

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					queryParams,
				},
				{ root: true }
			).then(({ data: { data = [], paginationKey = null } }) => {
				return { data, paginationKey };
			});
		},

		getAssistedUserMessages(
			{ dispatch, rootState },
			{ data, source, origin, paginationKey }
		) {
			const userId = data?.userId;
			const userUUID = rootState.secure?.uuid;

			const queryParams = {};

			/* istanbul ignore next */
			if (paginationKey) {
				Object.assign(queryParams, {
					...queryParams,
					paginationKey,
				});
			}

			const method = 'GET';
			const url = '/notifications/audit';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					queryParams,
				},
				{ root: true }
			).then((response) => {
				source.postMessage(
					{
						name: 'get-user-messages',
						payload: response.data,
						userUUID,
						userId,
					},
					origin
				);
			});
		},

		requestOption({ dispatch, rootState }, { data, source, origin }) {
			const { userId, action } = data;
			const userUUID = rootState.secure.uuid;
			const url = {
				unlock: `/assisted-channels/users/${userId}/${action}`,
				resetPassword: `/assisted-channels/users/${action}`,
				generateOtp: `/assisted-channels/users/${action}`,
			}[action];
			const method = {
				unlock: 'PATCH',
				resetPassword: 'PATCH',
				generateOtp: 'POST',
			}[action];
			const actionUpperCase = {
				unlock: 'UNLOCK_USER',
				resetPassword: 'RESET_PASSWORD',
				generateOtp: 'GENERATE_OTP',
			}[action];

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			)
				.then(() => {
					source.postMessage(
						{
							name: 'open-notification',
							text: `INFO.${actionUpperCase}.SUCCESS`,
							userUUID,
							userId,
						},
						origin
					);
					if (action === 'unlock') {
						source.postMessage(
							{
								name: 'update-session',
								payload: { state: 'ACTIVE', loginErrorCount: 0 },
								userUUID,
								userId,
							},
							origin
						);
					}
				})
				.catch((error) => {
					let text = `INFO.${actionUpperCase}.ERROR`;

					/* istanbul ignore else */
					if (error?.response) {
						const { errorCode, details } = error.response?.data;
						const UNLOCKING_ERROR = 'C4000000';

						if (errorCode === OTP_NO_TELEPHONE_ERROR) {
							text = 'OTP_ERROR_NO_TELEPHONE';
						}

						if (errorCode === OTP_NO_ACTIVE_USER_ERROR) {
							text = 'OTP_ERROR_USER_NOT_ACTIVE';
						}

						/* istanbul ignore else */
						if (details && errorCode === UNLOCKING_ERROR) {
							const [{ relatedFields }] = details;

							text =
								relatedFields[0] === 'passwordHash'
									? 'INFO.UNLOCK_USER.PASSWORD_ERROR'
									: 'INFO.UNLOCK_USER.PHONE_ERROR';
						}
					}

					source.postMessage(
						{
							name: 'open-notification',
							text,
							userUUID,
							userId,
						},
						origin
					);
				});
		},

		getSirvaseRequests(
			{ dispatch, rootState },
			{ data, source, origin, paginationKey, requestId, payload }
		) {
			const queryParams = {};
			let url = '/customer-support/request';
			const method = 'GET';

			/* istanbul ignore else */
			if (paginationKey) {
				Object.assign(queryParams, {
					...queryParams,
					paginationKey,
				});
			}

			if (requestId) {
				url = url.concat(`/${requestId}`);
			}

			const userUUID = rootState.secure.uuid;
			const { userId } = data;

			Object.assign(queryParams, {
				...queryParams,
				type: 'extended',
			});

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					queryParams,
					payload,
				},
				{ root: true }
			).then((res) => {
				source.postMessage(
					{
						name: 'sirvase-request',
						payload: res.data,
						userUUID,
						userId,
					},
					origin
				);
			});
		},

		recoverPassword({ dispatch, rootState }, { documentId, pan, pin }) {
			const { companyId } = rootState.app;
			const url = '/users/password';
			const method = 'POST';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload: {
						document_id: documentId,
						pan,
						pin,
						channel: 'WEB',
						company_id: companyId,
					},
				},
				{ root: true }
			);
		},

		resetPassword({ dispatch }, { oldPassword, password }) {
			const url = '/users/password';
			const method = 'PUT';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload: {
						oldPassword,
						password,
					},
				},
				{ root: true }
			);
		},

		getUserAddress({ dispatch }) {
			const url = '/users/user';
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			).then(({ data }) => data);
		},

		getUserConsents({ dispatch }) {
			const url = `/current/user/gdpr`;
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			).then(({ data }) => data);
		},

		updateUserAcceptance({ dispatch }, { service }) {
			const url = `/current/user/service-acceptance`;
			const method = 'POST';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload: { service },
				},
				{ root: true }
			);
		},

		updateUserConsents({ dispatch }, { consentsGDPR }) {
			const url = `/current/user/gdpr`;
			const method = 'PUT';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload: { consentsGDPR },
				},
				{ root: true }
			);
		},

		clearCache() {
			cache.clear();
		},
	},
};
