<template>
	<div
		class="l-modal"
		:class="classList"
		@keydown.capture="keyHandler"
	>
		<c-transition appear>
			<div
				class="l-modal__overlay"
				tabindex="-1"
			></div>
		</c-transition>

		<div class="l-modal__scrollable">
			<transition
				name="modal"
				appear
			>
				<div
					class="l-modal__dialog"
					:class="[{ '--dark': dark }]"
				>
					<div
						class="l-modal__document"
						role="document"
					>
						<div class="l-modal__nav">
							<slot name="inversis-header" />

							<span class="l-modal__close-button">
								<c-icon-button
									v-if="!modal"
									class="l-modal__icon-button"
									icon="@icons/close"
									size="l"
									tabindex="0"
									:title="$t('MODAL.CLOSE')"
									:aria-label="$t('MODAL.CLOSE')"
									data-testid="modal-close-button"
									@click="close"
								/>
							</span>
						</div>

						<section
							class="l-modal__container"
							:class="{ '--is-lighthouse': true }"
						>
							<div class="l-modal__wrapper">
								<div
									v-if="$slots.icon"
									class="l-modal__icon"
								>
									<slot name="icon" />
								</div>

								<span
									class="a11y-hide"
									a11y-focusable
									aria-labelledby="l-modal-title"
									tabindex="-1"
								></span>

								<header
									v-if="$slots.header"
									class="l-modal__header"
									data-testid="modal-header"
								>
									<h1
										id="l-modal-title"
										class="l-modal__header-title text-xl-bold"
										tabindex="-1"
									>
										<slot name="header" />
									</h1>
								</header>

								<div class="l-modal__content text-m-book">
									<slot />
								</div>

								<div
									v-if="$slots.subcontent"
									class="l-modal__sub-content text-m-book"
								>
									<slot name="subcontent" />
								</div>

								<div
									v-if="$slots.buttons"
									class="l-modal__buttons"
									data-testid="modal-buttons"
								>
									<slot name="buttons" />
								</div>

								<div
									v-if="$slots.actions"
									class="l-modal__actions text-m-medium"
									data-testid="modal-actions"
								>
									<slot name="actions" />
								</div>
							</div>
						</section>
					</div>
				</div>
			</transition>
		</div>
	</div>
</template>

<script>
import CTransition from '@components/c-transition';
import CIconButton from '@components/c-icon-button.vue';

export default {
	name: 'l-modal',

	components: {
		CTransition,
		CIconButton,
	},

	props: {
		fullscreen: { type: Boolean },
		almostFullscreen: { type: Boolean },
		modal: { type: Boolean },
		dark: { type: Boolean },
	},

	computed: {
		classList({ fullscreen, almostFullscreen }) {
			return {
				'--fullscreen': fullscreen,
				'--almost-fullscreen': almostFullscreen,
			};
		},
	},

	methods: {
		keyHandler($event) {
			const { keyCode } = $event;
			const buttons = this.$el.querySelectorAll('.button');
			const ESC = 27;
			const ENTER = 13;

			if (keyCode === ESC) {
				this.close();
			}

			if (keyCode === ENTER && buttons?.length) {
				const button =
					document.activeElement.localName === 'button'
						? document.activeElement
						: buttons[buttons.length - 1];

				/* istanbul ignore else */
				if (!button?.disabled && button?.click) {
					$event.preventDefault();
					button.click();
				}
			}
		},

		close() {
			if (!this.modal) {
				this.$el.dispatchEvent(new Event('close', { bubbles: true }));
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.modal-enter-active {
	animation: modalEnter 250ms ease-out;
}

@keyframes modalEnter {
	from {
		transform: scale(1.05);
		opacity: 0;
	}
}

.l-modal {
	display: flex;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: var(--spacing-xl);
	justify-content: center;
	align-items: center;
	perspective: 800px;
	will-change: transform;
	z-index: var(--z-index-modal);
}

.l-modal__overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	margin: 0;
	padding: 0;
	background: RGBA(
		var(--color-primary-dark-constant, var(--color-primary-dark)),
		0.75
	);
}

.l-modal__scrollable {
	display: flex;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: var(--spacing-l);
	align-items: center;
	justify-content: center;
	overflow-x: hidden;
	overflow-y: auto;
}

.l-modal__header {
	display: flex;
	justify-content: center;
	margin-bottom: var(--spacing-xl);
}

.l-modal__header-title {
	display: flex;
	position: relative;
	justify-content: center;
	padding-bottom: 10px;
}

.l-modal__header-title::after {
	content: '';
	position: absolute;
	width: 100%;
	max-width: 150px;
	bottom: 0;
	border-bottom: 4px solid RGB(var(--color-secondary));
}

.l-modal__content {
	overflow: hidden;
}

@media (spanning: single-fold-vertical) {
	.l-modal__scrollable {
		width: calc(env(fold-right) - env(fold-width));
	}
}

.l-modal__dialog {
	color: RGB(var(--l-modal-dialog-color, var(--color-text-primary)));
	display: flex;
	position: relative;
	width: 100%;
	max-height: 100%;
	justify-self: center;
	border-radius: var(--border-radius-m);
	background: var(--l-modal-dialog-bg, RGB(var(--color-surface-light)));
	box-shadow: var(--elevation-medium);
}

.l-modal__dialog.--dark {
	background-color: RGB(var(--color-primary));
}

.l-modal__document {
	display: flex;
	width: 100%;
	min-height: 100%;
	flex-direction: column;
	overflow-y: auto;
	overflow-x: hidden;
}

.l-modal__container {
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
	padding: var(--spacing-4xl) var(--spacing-s) var(--spacing-s);
}

.l-modal__nav {
	display: flex;
	flex-direction: row;
	position: absolute;
	width: 100%;
	/* Ensure the close icon is at the end of the container */
	justify-content: flex-end;
	align-items: center;
	background: var(--l-modal-color-gradient-header, var(--no-gradient)) 100%;
}

.l-modal__close-button {
	margin: var(--spacing-s);
}

.l-modal__close-button .l-modal__icon-button {
	color: RGB(var(--l-modal-dialog-color));
}

.l-modal__wrapper {
	display: flex;
	width: 100%;
	max-width: var(--full-width, 474px);
	flex-grow: 1;
	flex-direction: column;
	margin: 0 auto;
	text-align: center;
}

.l-modal__icon {
	color: RGB(var(--color-secondary));
	display: flex;
	justify-content: center;
	margin-bottom: var(--spacing-s);
	font-size: 40px;
}

.l-modal__sub-content {
	display: flex;
	width: 100%;
	margin: var(--spacing-xl) auto 0;
}

.l-modal__buttons {
	display: flex;
	width: 100%;
	justify-content: stretch;
	flex-wrap: wrap;
	margin: var(--spacing-2xl) auto var(--spacing-xs);
}

.l-modal__buttons /deep/ {
	& > .button {
		min-width: 140px;
		flex-grow: 1;
		flex-shrink: 0;
		flex-basis: 120px;
		margin: 8px 10px;
	}
}

@media ($xs-screen) {
	.l-modal__buttons /deep/ {
		& > .button {
			flex-basis: auto;
		}
	}
}

.l-modal__buttons /deep/ {
	& > .button:first-child:last-child {
		margin-left: 0;
		margin-right: 0;
	}
}

@media ($s-screen) {
	.l-modal__buttons {
		justify-content: center;
	}

	.l-modal__buttons /deep/ {
		> * {
			max-width: 320px;
		}
	}

	.l-modal {
		align-items: center;
	}

	.l-modal__dialog {
		width: 100%;
		max-width: 750px;
	}

	.l-modal__container {
		padding: 50px 0 40px;
	}
}

.l-modal.--fullscreen {
	.l-modal__scrollable {
		padding: 0;
	}

	.l-modal__dialog {
		margin: 0;
		max-width: none;
		width: 100%;
		height: 100%;
		border-radius: 0;
	}
}

.l-modal.--almost-fullscreen {
	.l-modal__close-button .l-modal__icon-button {
		font-size: 44px;
	}

	.l-modal__container {
		padding: 48px 0px 0px;
	}

	.l-modal__container.--is-lighthouse {
		padding: 70px 0px 0px;
	}

	.l-modal__dialog {
		margin: 0 auto;
		max-width: none;
		width: 95%;
		height: 100%;
		border-radius: 0;
	}

	.l-modal__wrapper {
		max-width: 100%;
		max-height: 100%;
	}

	.l-modal__content {
		min-height: 100%;
	}

	@media ($xs-screen) {
		.l-modal__close-button .l-modal__icon-button {
			font-size: 36px;
		}

		.l-modal__scrollable {
			padding: 0px;
		}

		.l-modal__dialog {
			width: 100%;
			height: 100%;
		}

		.l-modal__wrapper {
			margin: 0 auto;
			max-width: 100%;
			max-height: 100%;
		}
	}
}
</style>
