<template>
	<i
		class="c-icon"
		:class="classes"
		aria-hidden="true"
	>
		<svg
			class="c-icon__svg"
			:viewBox="viewBox"
			alt=""
		>
			<path
				class="c-icon__path"
				:d="svgPathData"
				v-bind="pathAttributes"
			/>
		</svg>
	</i>
</template>

<script>
/**
 * <c-icon src="@icons/file" />
 */
export default {
	name: 'c-icon',

	props: {
		src: { type: null },
		size: { type: String },
	},

	computed: {
		data({ src }) {
			return src.icon || src.definition?.icon || src.default?.icon;
		},

		width({ data: [width] }) {
			return width;
		},

		height({ data: [, height] }) {
			return height;
		},

		viewBox({ width, height }) {
			return `0 0 ${width} ${height}`;
		},

		svgPathData({ data: [, , svgPathData] }) {
			return svgPathData;
		},

		pathAttributes({ data: [, , , attributes] }) {
			return attributes
				? Object.fromEntries(attributes)
				: { fill: 'currentColor' };
		},

		classes({ size }) {
			return size ? `--${size}-size` : '';
		},
	},
};
</script>

<style lang="scss" scoped>
.c-icon {
	display: inline-block;
	width: 1em;
	height: 1em;
	overflow: visible;
	flex-shrink: 0;
}

.c-icon__svg {
	display: block;
}

.c-icon.--xs-size {
	font-size: 1.6rem;
}

.c-icon.--inherit-size {
	font-size: inherit;
}

.c-icon.--s-size {
	@extend %typo-s-light;
}

.c-icon.--m-size {
	@extend %typo-m-light;
}

.c-icon.--l-size {
	@extend %typo-l-light;
}

.c-icon.--xl-size {
	@extend %typo-xl-light;
}

.c-icon.--xxl-size {
	@extend %typo-xxl-light;
}
</style>
