<template>
	<button
		class="c-cbnk-button button"
		:class="classes"
		v-bind="$attrs"
		v-on="$listeners"
	>
		<span
			v-if="$slots.icon"
			class="c-cbnk-button__icon"
		>
			<slot name="icon" />
		</span>
		<span
			v-if="!isLoading"
			class="c-cbnk-button__text text-m-medium"
		>
			<slot />
		</span>
		<span
			v-else
			class="c-cbnk-button__dots"
		>
			<span class="c-cbnk-button__dot --first"></span>
			<span class="c-cbnk-button__dot --middle"></span>
			<span class="c-cbnk-button__dot --last"></span>
		</span>
	</button>
</template>

<script>
export default {
	name: 'c-cbnk-button',

	inheritAttrs: false,

	props: {
		outlined: { type: Boolean },
		secondary: { type: Boolean },
		isLoading: { type: Boolean },
		small: { type: Boolean },
		fullWidth: {
			type: Boolean,
			default: true,
		},
	},

	computed: {
		classes({ outlined, secondary, fullWidth, isLoading, small }) {
			return {
				'--is-outlined': outlined,
				'--is-secondary': secondary,
				'--is-full-width': fullWidth,
				'--is-loading': isLoading,
				'--is-small': small,
			};
		},
	},
};
</script>

<style lang="scss" scoped>
.c-cbnk-button {
	color: RGB(var(--color-text-white));
	display: flex;
	position: relative;
	height: 54px;
	align-items: center;
	justify-content: center;
	padding: var(--spacing-l);
	border-radius: var(--border-radius-xl);
	letter-spacing: 0;
	line-height: 1.5;
	text-decoration: none;
	white-space: nowrap;
	background-color: RGB(var(--color-secondary));
	box-shadow: var(--elevation-medium);
	--focus-ring-radius: var(--border-radius-xl);
}

.c-cbnk-button.--is-full-width {
	width: 100%;
}

.c-cbnk-button.--is-outlined.--is-small {
	min-width: 120px;
	height: 34px;
}

.c-cbnk-button.--is-secondary:disabled,
.c-cbnk-button:disabled {
	cursor: default;
	color: RGB(var(--color-text-disabled));
	background-color: RGB(var(--color-accent-disabled));
}

.c-cbnk-button.--is-secondary:disabled .c-cbnk-button__dot,
.c-cbnk-button:disabled .c-cbnk-button__dot {
	background-color: RGB(var(--color-text-disabled));
}

.c-cbnk-button:not(.--is-outlined):disabled {
	box-shadow: none;
}

.c-cbnk-button:not(:disabled):hover {
	color: RGB(var(--color-text-white));
	background-color: RGB(var(--color-secondary-light));
}

.c-cbnk-button:not(:disabled) .c-cbnk-button:not(:disabled):active {
	box-shadow: var(--elevation-low);
}

.c-cbnk-button.--is-secondary {
	color: RGB(var(--color-text-primary-dark));
	background-color: RGB(var(--color-secondary));
}

.c-cbnk-button.--is-secondary:not(:disabled):hover {
	color: RGB(var(--color-text-primary-dark));
	background-color: RGB(var(--color-secondary-light));
}

.c-cbnk-button.--is-outlined {
	color: RGB(var(--color-text-primary-dark));
	height: 44px;
	line-height: 1;
	background-color: RGB(var(--color-surface-light));
	box-shadow: 0px 0px 0px 2px RGB(var(--color-secondary-light));
}

.c-cbnk-button.--is-outlined .c-cbnk-button__icon {
	color: RGB(var(--color-secondary-light));
}

.c-cbnk-button.--is-outlined .c-cbnk-button__dot {
	background-color: RGB(var(--color-secondary));
}

.c-cbnk-button.--is-outlined:disabled {
	box-shadow: 0px 0px 0px 1px RGB(var(--color-accent-disabled));
}

.c-cbnk-button.--is-outlined:disabled .c-cbnk-button__icon {
	color: RGB(var(--color-accent-disabled));
}

.c-cbnk-button.--is-outlined:disabled .c-cbnk-button__dot {
	background-color: RGB(var(--color-accent-disabled));
}

.c-cbnk-button.--is-outlined:not(:disabled):hover .c-cbnk-button__icon {
	color: RGB(var(--color-text-primary));
}

.c-cbnk-button__icon {
	color: RGB(var(--color-text-primary));
	display: flex;
	padding-right: var(--spacing-m);
}

.c-cbnk-button__dots {
	display: flex;

	.c-cbnk-button__dot:not(:last-of-type) {
		margin-right: var(--spacing-s);
	}
}

.c-cbnk-button__dot {
	content: '';
	display: inline-block;
	width: 8px;
	height: 8px;
	border-radius: var(--border-radius-full);
	background-color: RGB(var(--color-text-white));
	animation: dot-flashing 500ms infinite alternate;

	&.--first {
		animation-delay: 0ms;
	}

	&.--middle {
		animation-delay: 150ms;
	}

	&.--last {
		animation-delay: 300ms;
	}
}

@keyframes dot-flashing {
	0% {
		transform: translateY(2px);
		opacity: 0.1;
	}

	100% {
		transform: translateY(-2px);
		opacity: 0.9;
	}
}
</style>
